import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';

import { Store } from '@ngrx/store';

import * as fromCoreStore from '@app/core/store';

import { Fuels, States, Company } from '@app/shared';
import { selectAuthUser } from '@app/auth/store';

@Component({
  selector: 'app-company-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnInit {
  @Input()
  props: { company: Company, linkType: string };
  @Output()
  update = new EventEmitter<Company>();
  @Output()
  create = new EventEmitter<Company>();

  states;
  fuels;
  form: FormGroup;
  updateStatus: boolean;

  constructor(
    private store: Store<fromCoreStore.State>,
    private statesService: States,
    private fuelsService: Fuels
  ) {
    this.states = this.statesService.getStates();
    this.fuels = this.fuelsService.getFuels();
  }

  ngOnInit() {
    // this.props?.company = { ...this.props?.company, ...{ "type": "Trucking", "name": "qewr", "street1": "123123", "city": "adsf", "state": "2", "zip": "adsf", "dot": "789456" } };
    this.form = new FormGroup({
      type: new FormControl(this.props?.company.type, [Validators.required]),
      name: new FormControl(this.props?.company.name, [Validators.required]),
      dba: new FormControl(this.props?.company.dba, [Validators.required]),
      street1: new FormControl(this.props?.company.street1, [Validators.required]),
      city: new FormControl(this.props?.company.city, [Validators.required]),
      state: new FormControl(this.props?.company.state, [Validators.required]),
      zip: new FormControl(this.props?.company.zip, [Validators.required]),
      phone: new FormControl(this.props?.company.phone, [Validators.required]),
      fax: new FormControl(this.props?.company.fax, [Validators.required]),
      email: new FormControl(this.props?.company.email, [Validators.required]),
      mc: new FormControl(this.props?.company.mc, [Validators.required]),
      dot: new FormControl(this.props?.company.dot, [Validators.required]),
      or: new FormControl(this.props?.company.or, [Validators.required]),
      ifta_state_id: new FormControl(this.props?.company.ifta_state_id, [Validators.required]),
      ifta: new FormControl(this.props?.company.ifta, [Validators.required]),
      ny_report: new FormControl(this.props?.company.ny_report, [Validators.required]),
      ny: new FormControl(this.props?.company.ny, [Validators.required]),
      ky: new FormControl(this.props?.company.ky, [Validators.required]),
      nm: new FormControl(this.props?.company.nm, [Validators.required])
    });
  }

  setValue(field, value) {
    if (this.form && this.form.controls[field]) {
      this.form.controls[field].setValue(value);
    }
  }

  showTrucking() { }

  fc(name) {
    return this.form.get(name) as FormControl;
  }

  save() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(field => {
        this.form.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }
    const companyFormLet: Company = { ...this.props?.company, ...this.form.value };
    if (this.props?.company.id) {
      this.store.dispatch(
        new fromCoreStore.UpdateCompany(
          { company: companyFormLet }
        )
      );
    } else {
      this.store.dispatch(
        new fromCoreStore.CreateCompany({
          company: companyFormLet,
          linkType: this.props.linkType
        })
      );
    }
    // this.store.dispatch(new fromCoreStore.CloseModal());
  }

  close() {
    this.store.dispatch(new fromCoreStore.CloseModal());
  }
}
