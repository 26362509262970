<div class="modal-header">
    <h4 class="modal-title">Edit Truck</h4>
    <button class="btn btn-link" type="button" (click)="close()"
      aria-label="Close">
      <span aria-hidden="true">
        <i class="fas fa-times"></i>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" role="form" [formGroup]="truckForm">
      <fieldset>
          <div class="form-group">
              <label class="control-label col-sm-2" for="status">Status*</label>
              <div class="col-sm-7">
                  <div class="btn-group">
                        <button type="button" [ngClass]="truckForm.value.status==0 ? 'btn-success' : 'btn-default'" class="btn" >
                            <label>
                                <input type="radio" name="status" formControlName="status" value="0">
                                Inactive
                            </label>
                        </button>
                        <button type="button" [ngClass]="truckForm.value.status==1 ? 'btn-success' : 'btn-default'" class="btn" (click)="setValue('end_date', '')">
                            <label>
                                <input type="radio" name="status" formControlName="status" value="1">
                                Active
                            </label>
                        </button>
                  </div>
              </div>
          </div>
          <div class="form-group ">
              <label class="control-label col-sm-2">Start Date*</label>
              <div class="col-sm-3" [ngClass]="{'has-error':fc('start_date').invalid && (fc('start_date').dirty || fc('start_date').touched)}">
                <app-date [startKey]="'date'" [parent]="{date:fc('start_date').value}" (onChange)="setValue('start_date', $event.date)" ></app-date>
                <div class="help-block" *ngIf="fc('start_date').invalid && (fc('start_date').dirty || fc('start_date').touched)">
                    Please start date is required.
                </div>  
            </div>
              <div *ngIf="truckForm.value.status == 0"  [ngClass]="{'has-error':(!fc('end_date').value)}">
                  <label class="control-label col-sm-1">End</label>
                  <div class="col-sm-3">
                        <app-date [startKey]="'date'" [parent]="{date:fc('end_date').value}" (onChange)="setValue('end_date', $event.date)" ></app-date>
                  </div>
              </div>
          </div>
          <div class="form-group ">
              <div [ngClass]="{'has-error':fc('unit').invalid && (fc('unit').dirty || fc('unit').touched)}">
                  <label class="control-label col-sm-2" for="unit">Unit*</label>
                  <div class="col-sm-3">
                      <input class="form-control input" name="unit" type="text" formControlName="unit" required>
                      <div class="help-block" *ngIf="fc('unit').invalid && (fc('unit').dirty || fc('unit').touched)">
                        Please enter Unit Number.
                    </div>
                  </div>
              </div>
              <div>
                  <label class="control-label col-sm-1" for="year">Year</label>
                  <div class="col-sm-3">
                      <input class="form-control input" type="text" formControlName="year">
                      <span class="help-block"></span>
                  </div>
              </div>
          </div>
          <div class="form-group ">
              <label class="control-label col-sm-2" for="make">Make</label>
              <div class="col-sm-3">
                  <input class="form-control input" type="text" formControlName="make">
                  <span class="help-block"></span>
              </div>
              <label class="control-label col-sm-1" for="model">Model</label>
              <div class="col-sm-3">
                  <input class="form-control input" type="text" formControlName="model">
                  <span class="help-block"></span>
              </div>
          </div>
          <div class="form-group">
              <label class="control-label col-sm-2">VIN</label>
              <div class="col-sm-7">
                  <input class="form-control input" type="text" formControlName="vin">
                  <span class="help-block"></span>
              </div>
          </div>
          <div class="form-group ">
              <label class="control-label col-sm-2">Fuel</label>
              <div class="col-sm-3" [ngClass]="{'has-error':(!fc('fuel_id').value) }">
                  <select class="form-control" formControlName="fuel_id" required>
                        <option value="" selected="selected">
                                Please select Fuel!
                        </option>
                        <option *ngFor="let fuel of fuels" [ngValue]="fuel.id">
                                {{ fuel.name }}
                        </option>
                  </select>
              </div>
              <label class="control-label col-sm-1">Axles</label>
              <div class="col-sm-3">
                  <input class="form-control input" type="text" formControlName="axles" placeholder="ex. 3">
                  <span class="help-block"></span>
              </div>
          </div>
          <div class="form-group">
              <label class="control-label col-sm-2">Plate</label>
              <div class="col-sm-3">
                  <input class="form-control input" type="text" formControlName="lic">
                  <span class="help-block"></span>
              </div>
              <div class="col-sm-4">
                    <select class="form-control" formControlName="state_id" required>
                        <option value="">
                            Select Plate State
                        </option>
                        <option *ngFor="let state of states" [ngValue]="state.id">
                            {{ state.name }}
                        </option>
                    </select>
                  <span class="help-block"></span>
              </div>
          </div>
          <div class="form-group ">
            <div [ngClass]="{'has-error':!fc('w_loaded').value && fc('w_loaded').touched}">
                <label class="control-label col-sm-2">Weight Loaded</label>
                <div class="col-sm-3">
                    <input class="form-control input" type="text" name="w_loaded" formControlName="w_loaded" placeholder="ex. 80000" required>
                    <span class="help-block"></span>
                </div> 
            </div>
              <div [ngClass]="{'has-error':!fc('w_unloaded').value && fc('w_unloaded').touched}">
                  <label class="control-label col-sm-1" for="model" style="padding-left: 5px;">Unload</label>
                  <div class="col-sm-3">
                      <input class="form-control input" type="text" name="w_unloaded" formControlName="w_unloaded" placeholder="ex. 34500" required>
                      <span class="help-block"></span>
                  </div>
              </div>
          </div>
      </fieldset>
  </form>
  </div>
  <div class="modal-footer">
    <div class="form-actions">
      <button type="submit" (click)="onSubmit()" class="btn btn-primary btn-lg btn-block">Save</button>
    </div>
  </div>
  