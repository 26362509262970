import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, Renderer2, ElementRef } from '@angular/core';
import { AuthUser } from '@app/shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';

import * as fromCoreStore from '@app/core/store';
import * as fromReportStore from '@app/reports/store';
import { Subject, Subscription, takeUntil, tap } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html'
})
export class ReportDetailsComponent implements OnInit, OnDestroy {
  @Input()
  props;

  viewReport$;
  report;

  destroyed$ = new Subject<boolean>();

  constructor(
    private coreStore: Store<fromCoreStore.State>,
    private reportStore: Store<fromReportStore.State>
  ) {}

  ngOnInit() {
    this.viewReport$ = this.reportStore.select(fromReportStore.selectViewReport)
    .pipe(
      takeUntil(this.destroyed$),
      tap(report => {
        console.log(report)
      })
    ).subscribe()

    
  }

  close() {
    this.coreStore.dispatch(new fromCoreStore.CloseModal());
    this.coreStore.dispatch(new fromReportStore.GetReport({ viewId: null }));
  }

  ngOnDestroy() {
    this.viewReport$.unsubscribe();
  }

}
