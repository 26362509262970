<div class="modal-header">
    <button class="btn btn-link" type="button" (click)="close()"
      aria-label="Close">
      <span aria-hidden="true">
        <i class="fas fa-times"></i>
      </span>
    </button>
</div>
<!-- <pre>{{reportContent}}</pre> -->
<div class="">
    <!-- <div [innerHTML]="reportContent"></div> -->
</div>
<div class="modal-footer"></div>