{
  "name": "iFuelTax-NG2",
  "version": "0.1.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4200 --host=0.0.0.0 --disable-host-check",
    "build": "ng build --configuration=prod --source-map",
    "test": "jest --config src/jest.config.js",
    "test:live": "jest --config src/jest.config.js --watchAll",
    "lint": "ng lint",
    "deploy-dev": "ng build --configuration=dev && firebase deploy --only hosting:dev-app",
    "deploy-prod": "ng build --configuration=prod && firebase deploy --only hosting:app && node build-script.js",
    "build-stats": "ng build --configuration=prod --output-hashing=none --stats-json",
    "stats": "webpack-bundle-analyzer dist/app/stats.json",
    "stats-exp": "ng build && source-map-explorer dist/app/**/*.js",
    "e2e": "ng e2e",
    "prepare": "husky"
  },
  "private": true,
  "dependencies": {
    "@agm/core": "1.1.0",
    "@angular/animations": "18.0.5",
    "@angular/common": "18.0.5",
    "@angular/compiler": "18.0.5",
    "@angular/core": "18.0.5",
    "@angular/forms": "18.0.5",
    "@angular/localize": "18.0.5",
    "@angular/platform-browser": "18.0.5",
    "@angular/platform-browser-dynamic": "18.0.5",
    "@angular/router": "18.0.5",
    "@ng-bootstrap/ng-bootstrap": "13.0.0",
    "@ngrx/core": "1.2.0",
    "@ngrx/effects": "18.0.1",
    "@ngrx/entity": "18.0.1",
    "@ngrx/operators": "18.0.0",
    "@ngrx/router-store": "18.0.1",
    "@ngrx/store": "18.0.1",
    "@ngrx/store-devtools": "18.0.1",
    "@popperjs/core": "2.11.7",
    "@sentry/angular": "7.47.0",
    "@sentry/tracing": "7.47.0",
    "@stripe/stripe-js": "1.52.0",
    "@tkrotoff/bootstrap-floating-label": "0.11.5",
    "angular2-multiselect-dropdown": "5.0.4",
    "bootstrap": "5.2.3",
    "chart.js": "4.4.3",
    "core-js": "3.1.4",
    "flatpickr": "4.6.13",
    "jquery": "3.6.1",
    "moment": "2.29.4",
    "moment-timezone": "0.5.33",
    "ng2-charts": "6.0.1",
    "ng2-dragula": "5.1.0",
    "popper.js": "1.16.1",
    "rxjs": "7.8.0",
    "selectize": "0.12.6",
    "tslib": "1.9.0",
    "zone.js": "^0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "18.0.6",
    "@angular/cli": "18.0.6",
    "@angular/compiler-cli": "18.0.5",
    "@ngrx/schematics": "18.0.1",
    "@types/google-maps": "^3.2.6",
    "@types/jasmine": "5.1.0",
    "@types/jest": "^29.5.11",
    "@types/marked": "4.0.8",
    "@types/node": "18.15.11",
    "@types/sortablejs": "1.15.1",
    "axios": "1.3.5",
    "codelyzer": "6.0.2",
    "husky": "^9.0.11",
    "jasmine-core": "4.2.0",
    "jasmine-spec-reporter": "~7.0.0",
    "jest": "^29.7.0",
    "jest-fail-on-console": "^3.3.0",
    "jest-preset-angular": "^13.1.4",
    "karma-cli": "~2.0.0",
    "protractor": "~7.0.0",
    "source-map-explorer": "2.5.2",
    "ts-node": "~10.1.0",
    "tslint": "~6.1.3",
    "typescript": "5.4.5",
    "webpack-bundle-analyzer": "4.7.0"
  }
}
