import { Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/core/store';

// import { TruckFormComponent } from '@app/trucks/components/truck-form.component';
import { TruckFormComponent } from '@app/trucks/components/truck-form/truck-form.component';
import { CompanyFormComponent } from '@app/companies/components/company-form/company-form.component';
import { UserFormComponent } from '@app/settings/components/user-form/user-form.component';
import { CheckoutFormComponent } from '@app/settings/components/checkout-form/checkout-form.component';
import { LoginPageComponent } from '@app/auth/containers/login-page.component';
import { SubjectDestroyed } from '@app/shared';
import { AccountDetailsComponent } from '@app/manage/components/account-details/account-details.component';
import { ReportDetailsComponent } from '@app/reports/components/report-details/report-details.component';

@Component({
  selector: 'app-modal-root',
  template: ``
})
export class ModalRootComponent extends SubjectDestroyed implements OnInit {
  modal;

  MODAL_COMPONENTS = {
    TRUCK_FORM: TruckFormComponent,
    COMPANY_FORM: CompanyFormComponent,
    USER_FORM: UserFormComponent,
    CHECKOUT_FORM: CheckoutFormComponent,
    LOGIN: LoginPageComponent,
    ACCOUNT_DETAILS: AccountDetailsComponent,
    REPORT_DETAILS: ReportDetailsComponent,
  };

  constructor(
    private modalService: NgbModal,
    private store: Store<fromStore.State>
  ) {
    super();
  }

  ngOnInit() {
    this.store
      .select(fromStore.getModal)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(modal => {
        if (modal) {
          if (!this.modal) {
            this.openModal(modal);
          }
        } else {
          if (this.modal) {
            this.modal.close();
            this.modal = null;
          }
        }
      });
  }

  openModal(modal) {
    let modalOptions = {};
    modalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    };

    const SpecificModal = this.MODAL_COMPONENTS[modal.type];
    if (modal.props) {
      modalOptions = {...modalOptions, ...modal.props};
    }
    this.modal = this.modalService.open(SpecificModal, modalOptions);
    if (modal.props) {
      this.modal.componentInstance.props = modal.props;
    }
  }
}
