<div class="modal-header">
    <h4 class="modal-title">Edit Company {{props?.linkType}}</h4>
    <button class="btn btn-link" type="button" (click)="close()"
      aria-label="Close">
      <span aria-hidden="true">
        <i class="fas fa-times"></i>
      </span>
    </button>
  </div>
  <div class="modal-body">
      <form class="form-horizontal" role="form" [formGroup]="form">
          <fieldset>
             <div class="form-group" [ngClass]="{'has-error':fc('type').invalid && (fc('type').dirty || fc('type').touched)}">
                 <label class="control-label col-sm-3" for="status">Company Type*</label>
                 <div class="col-sm-7">
                     <div class="btn-group">
                        <button type="button" [ngClass]="(form.value.type=='Trucking') ? 'btn-success' : 'btn-default'" class="btn" >
                            <label>
                                <input type="radio" formControlName="type" name="type" value="Trucking">
                                Trucking
                            </label>
                        </button>
                        <button type="button" [ngClass]="(form.value.type=='Service') ? 'btn-success' : 'btn-default'" class="btn">
                            <label>
                                <input type="radio" formControlName="type" name="type" value="Service">
                                Service
                            </label>
                        </button>
                     </div>
                     <div class="help-block" *ngIf="fc('type').invalid && (fc('type').dirty || fc('type').touched)">
                        Please select Company Type it's required.
                    </div>
                 </div>
             </div>
             <div class="form-group" [ngClass]="{'has-error':fc('name').invalid && (fc('name').dirty || fc('name').touched)}">
                 <label for="name" class="control-label col-sm-3 ">Company Name*</label>
                 <div class="col-sm-7">
                     <input class="form-control" type="text" formControlName="name" name="name" required>
                     <div class="help-block" *ngIf="fc('name').invalid && (fc('name').dirty || fc('name').touched)">
                        Please enter a Company Name it's required.
                    </div>
                </div>
             </div>
             <div class="form-group" [ngClass]="{'has-error':fc('dba').invalid && (fc('dba').dirty || fc('dba').touched)}">
                 <label for="name" class="control-label col-sm-3 ">DBA*</label>
                 <div class="col-sm-7">
                     <input class="form-control" type="text" formControlName="dba" name="dba">
                 </div>
             </div>
             <div class="form-group" [ngClass]="{'has-error':fc('street1').invalid && (fc('street1').dirty || fc('street1').touched)}">
                 <label for="street1" class="control-label col-sm-3 ">Street Address*</label>
                 <div class="col-sm-7">
                     <input class="form-control" type="text" formControlName="street1" name="street1">
                 </div>
             </div>
             <div class="form-group" [ngClass]="{'has-error':fc('city').invalid && (fc('city').dirty || fc('city').touched)}">
                 <label for="city" class="control-label col-sm-3 ">City*</label>
                 <div class="col-sm-7">
                     <input class="form-control" type="text" formControlName="city" name="city">
                 </div>
             </div>
             <div class="form-group row">
                <label 
                for="state" 
                class="control-label col-sm-3" 
                [ngStyle]="{'color':fc('state').invalid && (fc('state').dirty || fc('state').touched) ? '#dc3545' : ''}"
                >State*</label>
                 <div class="col-sm-3" [ngClass]="{'has-error':fc('state').invalid && (fc('state').dirty || fc('state').touched)}">
                     <select class="form-control" formControlName="state" name="state">
                        <option *ngFor="let state of states" [ngValue]="state.id">
                            {{ state.name }}
                        </option>
                     </select>
                 </div>
                 <label 
                 for="zip" 
                 class="control-label col-sm-1 "
                 [ngStyle]="{'color':fc('zip').invalid && (fc('zip').dirty || fc('zip').touched) ? '#dc3545' : ''}"
                 >Zip*</label>
                 <div class="col-sm-3" [ngClass]="{'has-error':fc('zip').invalid && (fc('zip').dirty || fc('zip').touched)}">
                     <input class="form-control" type="text" formControlName="zip" name="zip" size="5">
                 </div>
             </div>
             <div class="form-group row">
                 <label 
                 for="dot"
                 class="control-label col-sm-3 " 
                 [ngStyle]="{'color':fc('phone').invalid && (fc('phone').dirty || fc('phone').touched) ? '#dc3545' : ''}"
                 >Phone*</label>
                 <div class="col-sm-3" [ngClass]="{'has-error':fc('phone').invalid && (fc('phone').dirty || fc('phone').touched)}">
                     <input class="form-control" type="text" formControlName="phone" name="phone">
                 </div>
                 <label 
                 class="control-label col-sm-1"
                 [ngStyle]="{'color':fc('fax').invalid && (fc('fax').dirty || fc('fax').touched) ? '#dc3545' : ''}"
                 >Fax*</label>
                 <div class="col-sm-3" [ngClass]="{'has-error':fc('fax').invalid && (fc('fax').dirty || fc('fax').touched)}">
                     <input class="form-control" type="text" formControlName="fax" name="fax">
                 </div>
             </div>
             <div class="form-group" [ngClass]="{'has-error':fc('email').invalid && (fc('email').dirty || fc('email').touched)}">
                 <label for="email" class="control-label col-sm-3 ">E-mail*</label>
                 <div class="col-sm-7">
                     <input class="form-control" type="text" formControlName="email" name="email">
                 </div>
             </div>
             <div class="form-group" [ngClass]="{'has-error':fc('mc').invalid && (fc('mc').dirty || fc('mc').touched)}">
                 <label for="mc" class="control-label col-sm-3 ">MC*</label>
                 <div class="col-sm-7">
                     <input class="form-control" type="text" formControlName="mc" name="mc" required>
                     <div class="help-block" *ngIf="fc('mc').invalid && (fc('mc').dirty || fc('mc').touched)">
                        Please enter a MC Number it's required.
                     </div>
                 </div>
             </div>
             <div class="form-group row">
                <label 
                for="dot" 
                class="control-label col-sm-3 "
                [ngStyle]="{'color':fc('dot').invalid && (fc('dot').dirty || fc('dot').touched) ? '#dc3545' : ''}"
                >DOT*</label>
                <div class="col-sm-3" [ngClass]="{'has-error':fc('dot').invalid && (fc('dot').dirty || fc('dot').touched)}">
                    <input class="form-control" type="text" formControlName="dot" name="dot" required>
                    <div class="help-block" *ngIf="fc('dot').invalid && (fc('dot').dirty || fc('dot').touched)">
                        Please enter a DOT Number it's required.
                    </div>
                </div>
                 <label 
                 class="control-label col-sm-1"
                 [ngStyle]="{'color':fc('or').invalid && (fc('or').dirty || fc('or').touched) ? '#dc3545' : ''}"
                 >OR</label>
                 <div class="col-sm-3" [ngClass]="{'has-error':fc('dot').invalid && (fc('dot').dirty || fc('dot').touched)}">
                     <input class="form-control" type="text" formControlName="or" name="or">
                 </div>
             </div>
             <div class="form-group row" [ngClass]="{'has-error':fc('ifta').invalid && (fc('ifta').dirty || fc('ifta').touched)}">
                 <label class="control-label col-sm-3 ">IFTA #</label>
                 <div class="col-sm-3">
                     <input class="form-control" type="text" formControlName="ifta" name="ifta">
                 </div>
                 <div class="col-sm-4">
                     <select class="form-control" formControlName="ifta_state_id" name="ifta_state_id">
                       <option value="">Select IFTA State</option>
                       <option *ngFor="let state of states" [ngValue]="state.id">
                            {{ state.abbr }}
                        </option>
                      </select>
                 </div>
             </div>
             <div class="form-group row" [ngClass]="{'has-error':fc('ny').invalid && (fc('ny').dirty || fc('ny').touched)}">
                 <label class="control-label col-sm-3 ">NY #</label>
                 <div class="col-sm-3">
                     <input class="form-control" type="text" formControlName="ny" name="ny">
                 </div>
                 <div class="col-sm-4">
                     <select class="form-control" formControlName="ny_report" name="ny_report">
                         <option value="">Select NY Method</option>
                         <option value="0">Unloaded Weight Method</option>
                         <option value="1">Gross Weight Method</option>
                     </select>
                 </div>
             </div>
             <div class="form-group row">
                 <label 
                 class="control-label col-sm-3 "
                 [ngStyle]="{'color':fc('ky').invalid && (fc('ky').dirty || fc('ky').touched) ? '#dc3545' : ''}"
                 >KY</label>
                 <div class="col-sm-3" [ngClass]="{'has-error':fc('ky').invalid && (fc('ky').dirty || fc('ky').touched)}">
                     <input class="form-control" type="text" formControlName="ky" name="ky">
                 </div>
                 <label 
                 class="control-label col-sm-1 "
                 [ngStyle]="{'color':fc('nm').invalid && (fc('nm').dirty || fc('nm').touched) ? '#dc3545' : ''}"
                 >NM</label>
                 <div class="col-sm-3" [ngClass]="{'has-error':fc('nm').invalid && (fc('nm').dirty || fc('nm').touched)}">
                     <input class="form-control" type="text" formControlName="nm" name="nm">
                 </div>
             </div>
         </fieldset>
     </form>
    </div>
    <div class="modal-footer">
    <div class="form-actions">
        <button (click)="save()" class="btn btn-primary btn-lg btn-block">Save</button>
    </div>
  </div>
  